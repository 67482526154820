<template>
  <DaftarHadirRadirForm mode="Ubah" module="Daftar Hadir Radir"> </DaftarHadirRadirForm>
</template>

<script>
import DaftarHadirRadirForm from './form';

const DaftarHadirRadirUpdate = {
  name: 'DaftarHadirRadirUpdate',
  components: { DaftarHadirRadirForm },
};

export default DaftarHadirRadirUpdate;
</script>
